import posthog from 'posthog-js';

export default defineNuxtPlugin(() => {
    const runtimeConfig = useRuntimeConfig();
    const posthogClient = posthog.init(runtimeConfig.public.posthogPublicKey, {
        api_host: runtimeConfig.public.posthogHost || 'https://eu.posthog.com',
        capture_pageview: false,
        // disable persistence so initially nothing is stored on users machine, gets activated when user opts in to cookies
        disable_persistence: true,
        // Enalbe Heatmap data collection
        enable_heatmaps: true,
        // Debug Mode
        // loaded: (posthog) => {
        //     if (runtimeConfig.public.env === 'development') posthog.debug();
        // },
    });

    const router = useRouter();
    // Make sure that pageviews are captured with each route change
    router.afterEach((to) => {
        posthog.capture('$pageview', {
            current_url: to.fullPath,
        });
    });

    return {
        provide: {
            posthog: () => posthogClient,
        },
    };
});
