import revive_payload_client_MuK2G6gMMv from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_tb37aT5ufa from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ok6z5ZJWN2 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_TmPtsZofp0 from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.24.0_vite@5.4.9_vue@3.5.12/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_dMXUe2cxRr from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_1SxbaLfrhR from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_g4FD2nf4iw from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_s7ONPaycw9 from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_4kg5sD8WHk from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_rollup@4.24.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_togae0mDHA from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_rollup@4.24.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import motion_oH0dWZs9gi from "/opt/build/repo/node_modules/.pnpm/@vueuse+motion@2.2.6_rollup@4.24.0_vue@3.5.12/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import titles_SrrSlYpGHl from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.11_@nuxt+devtools@1.6.0_@vue+compiler-core@3.5.12_h3@1.13.0_nuxt@3.13.2__jz4l5daqqmhr5yufvcwmxydplu/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_5jlmFgxBhD from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.11_@nuxt+devtools@1.6.0_@vue+compiler-core@3.5.12_h3@1.13.0_nuxt@3.13.2__jz4l5daqqmhr5yufvcwmxydplu/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import plugin_kEmm3MA96i from "/opt/build/repo/node_modules/.pnpm/@storyblok+nuxt@6.0.13/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import _01posthog_client_MKLKwXG2uG from "/opt/build/repo/plugins/01posthog.client.ts";
import _02cookiefirst_client_napE60kAZE from "/opt/build/repo/plugins/02cookiefirst.client.ts";
import _03registerSwiper_XLkAI2dy4M from "/opt/build/repo/plugins/03registerSwiper.ts";
import _04sentry_client_e8XkWREx89 from "/opt/build/repo/plugins/04sentry.client.ts";
import _05chunkReloadError_UE8EuDbuyH from "/opt/build/repo/plugins/05chunkReloadError.ts";
export default [
  revive_payload_client_MuK2G6gMMv,
  unhead_tb37aT5ufa,
  router_ok6z5ZJWN2,
  _0_siteConfig_TmPtsZofp0,
  payload_client_dMXUe2cxRr,
  navigation_repaint_client_1SxbaLfrhR,
  check_outdated_build_client_g4FD2nf4iw,
  plugin_vue3_s7ONPaycw9,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4kg5sD8WHk,
  i18n_togae0mDHA,
  motion_oH0dWZs9gi,
  titles_SrrSlYpGHl,
  defaults_5jlmFgxBhD,
  plugin_kEmm3MA96i,
  _01posthog_client_MKLKwXG2uG,
  _02cookiefirst_client_napE60kAZE,
  _03registerSwiper_XLkAI2dy4M,
  _04sentry_client_e8XkWREx89,
  _05chunkReloadError_UE8EuDbuyH
]