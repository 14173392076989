import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBasicPage, LazyBasicSection, LazyBookSalesMeeting, LazyBrandCards, LazyBrandPage, LazyBrandSwiper, LazyCarSectionBrand, LazyCarSectionFiltered, LazyCarSectionPromotionWheel, LazyCarSectionSelectable, LazyChargePricePage, LazyChartComparisonCarRange, LazyClimateSavings, LazyCountdownTimer, LazyDamageReportPage, LazyEBonusTile, LazyEBonusTiles, LazyFaqBrand, LazyFaqOverviewSection, LazyFaqPage, LazyFaqTile, LazyFooterAssets, LazyGdprPage, LazyGlobalComponent, LazyGlobalPage, LazyHeroAboutUs, LazyHeroBasic, LazyHeroBrandOverview, LazyHeroBrandOverviewWithSelectorPreFilter, LazyHeroBrandWithCar, LazyHeroPreorderOverviewWithCar, LazyHeroWithCar, LazyHeroWithCenteredImage, LazyHeroWithSelectorPreFilter, LazyImageFullWidth, LazyImprintPage, LazyIncludedItem, LazyIncludedItemWrapper, LazyJumplinkCardWrapper, LazyJumplinkSection, LazyLinkSectionBrands, LazyLinkSectionRandom, LazyMagazineArticle, LazyMagazineArticleWrapper, LazyMarketingCard, LazyMarketingCardWrapper, LazyMenuLink, LazyNewsletterSection, LazyPhoneNumber, LazyPreorderCarCard, LazyPreorderModelPage, LazyPreorderModelTechnicalDetails, LazyPreorderOverviewCarCardWrapper, LazyPromotionCarOfTheMonth, LazyPromotionCarOfTheMonthWithSelectorPreFilter, LazyPromotionSection, LazyReasonCard, LazyReasonCardWrapper, LazyReviewCard, LazyReviewWrapper, LazySelectorFilterCard, LazySelectorFilterCardWrapper, LazySeoContentArea, LazySeoContentRow, LazySeoImage, LazySeoText, LazySeoTile, LazySocialMediaLink, LazyTabWithImage, LazyTabWrapper, LazyTableBasic, LazyTableCarProperties, LazyTableComparison, LazyTaglineSection, LazyTeammemberCard, LazyTeammemberWrapper, LazyTextCard, LazyThreeStepsExplanation, LazyVideoStandalone, LazyYoutubeVideoCard, LazyYoutubeVideoWrapper } from '#components'
const lazyGlobalComponents = [
  ["BasicPage", LazyBasicPage],
["BasicSection", LazyBasicSection],
["BookSalesMeeting", LazyBookSalesMeeting],
["BrandCards", LazyBrandCards],
["BrandPage", LazyBrandPage],
["BrandSwiper", LazyBrandSwiper],
["CarSectionBrand", LazyCarSectionBrand],
["CarSectionFiltered", LazyCarSectionFiltered],
["CarSectionPromotionWheel", LazyCarSectionPromotionWheel],
["CarSectionSelectable", LazyCarSectionSelectable],
["ChargePricePage", LazyChargePricePage],
["ChartComparisonCarRange", LazyChartComparisonCarRange],
["ClimateSavings", LazyClimateSavings],
["CountdownTimer", LazyCountdownTimer],
["DamageReportPage", LazyDamageReportPage],
["EBonusTile", LazyEBonusTile],
["EBonusTiles", LazyEBonusTiles],
["FaqBrand", LazyFaqBrand],
["FaqOverviewSection", LazyFaqOverviewSection],
["FaqPage", LazyFaqPage],
["FaqTile", LazyFaqTile],
["FooterAssets", LazyFooterAssets],
["GdprPage", LazyGdprPage],
["GlobalComponent", LazyGlobalComponent],
["GlobalPage", LazyGlobalPage],
["HeroAboutUs", LazyHeroAboutUs],
["HeroBasic", LazyHeroBasic],
["HeroBrandOverview", LazyHeroBrandOverview],
["HeroBrandOverviewWithSelectorPreFilter", LazyHeroBrandOverviewWithSelectorPreFilter],
["HeroBrandWithCar", LazyHeroBrandWithCar],
["HeroPreorderOverviewWithCar", LazyHeroPreorderOverviewWithCar],
["HeroWithCar", LazyHeroWithCar],
["HeroWithCenteredImage", LazyHeroWithCenteredImage],
["HeroWithSelectorPreFilter", LazyHeroWithSelectorPreFilter],
["ImageFullWidth", LazyImageFullWidth],
["ImprintPage", LazyImprintPage],
["IncludedItem", LazyIncludedItem],
["IncludedItemWrapper", LazyIncludedItemWrapper],
["JumplinkCardWrapper", LazyJumplinkCardWrapper],
["JumplinkSection", LazyJumplinkSection],
["LinkSectionBrands", LazyLinkSectionBrands],
["LinkSectionRandom", LazyLinkSectionRandom],
["MagazineArticle", LazyMagazineArticle],
["MagazineArticleWrapper", LazyMagazineArticleWrapper],
["MarketingCard", LazyMarketingCard],
["MarketingCardWrapper", LazyMarketingCardWrapper],
["MenuLink", LazyMenuLink],
["NewsletterSection", LazyNewsletterSection],
["PhoneNumber", LazyPhoneNumber],
["PreorderCarCard", LazyPreorderCarCard],
["PreorderModelPage", LazyPreorderModelPage],
["PreorderModelTechnicalDetails", LazyPreorderModelTechnicalDetails],
["PreorderOverviewCarCardWrapper", LazyPreorderOverviewCarCardWrapper],
["PromotionCarOfTheMonth", LazyPromotionCarOfTheMonth],
["PromotionCarOfTheMonthWithSelectorPreFilter", LazyPromotionCarOfTheMonthWithSelectorPreFilter],
["PromotionSection", LazyPromotionSection],
["ReasonCard", LazyReasonCard],
["ReasonCardWrapper", LazyReasonCardWrapper],
["ReviewCard", LazyReviewCard],
["ReviewWrapper", LazyReviewWrapper],
["SelectorFilterCard", LazySelectorFilterCard],
["SelectorFilterCardWrapper", LazySelectorFilterCardWrapper],
["SeoContentArea", LazySeoContentArea],
["SeoContentRow", LazySeoContentRow],
["SeoImage", LazySeoImage],
["SeoText", LazySeoText],
["SeoTile", LazySeoTile],
["SocialMediaLink", LazySocialMediaLink],
["TabWithImage", LazyTabWithImage],
["TabWrapper", LazyTabWrapper],
["TableBasic", LazyTableBasic],
["TableCarProperties", LazyTableCarProperties],
["TableComparison", LazyTableComparison],
["TaglineSection", LazyTaglineSection],
["TeammemberCard", LazyTeammemberCard],
["TeammemberWrapper", LazyTeammemberWrapper],
["TextCard", LazyTextCard],
["ThreeStepsExplanation", LazyThreeStepsExplanation],
["VideoStandalone", LazyVideoStandalone],
["YoutubeVideoCard", LazyYoutubeVideoCard],
["YoutubeVideoWrapper", LazyYoutubeVideoWrapper],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
