<template>
    <ConfigProvider :use-id="useIdFunction">
        <NuxtLoadingIndicator color="#16BAE7" :throttle="0" />
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </ConfigProvider>
</template>

<script setup lang="ts">
    import { ConfigProvider } from 'radix-vue';

    const useIdFunction = () => useId();
    provideHeadlessUseId(() => useId());

    useSchemaOrg([
        defineLocalBusiness({
            type: 'AutoRental',
            name: 'Instadrive GmbH',
            image: 'https://insta-drive.com/app/uploads/2021/11/Instadrive_Blue.png',
            telephone: '+43 0800 40 40 64',
            address: {
                addressCountry: 'AT',
                addressLocality: 'Vienna, Austria',
                addressRegion: 'VIE',
                postalCode: '1160',
                streetAddress: 'Lerchenfelder Gürtel 43/1',
            },
            priceRange: '€€€',
            aggregrateRating: {
                ratingValue: 4.3,
                reviewCount: 134,
                bestRating: 5,
                worstRating: 1,
            },
        }),
    ]);
</script>
