import type { RouteLocationNormalized } from 'vue-router';

export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter();
    const config = useRuntimeConfig();

    function reloadAppAtPath(to: RouteLocationNormalized) {
        const isHash = 'href' in to && (to.href as string)[0] === '#';
        const path = isHash
            ? config.public.hostName + (to as any).href
            : config.public.hostName + to.fullPath;

        reloadNuxtApp({ path, persistState: false });
    }

    nuxtApp.hook('app:chunkError', () => {
        reloadAppAtPath(router.currentRoute.value);
    });
});
