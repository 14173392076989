<script setup lang="ts">
    import type { HTMLAttributes } from 'vue';
    import { Primitive, type PrimitiveProps } from 'radix-vue';
    import { type ButtonVariants, buttonVariants } from '.';
    import { cn } from '@/utils/ui';

    interface Props extends PrimitiveProps {
        intent?: ButtonVariants['intent'];
        color?: ButtonVariants['color'];
        size?: ButtonVariants['size'];
        class?: HTMLAttributes['class'];
        to?: string;
    }

    const props = withDefaults(defineProps<Props>(), {
        intent: 'primary',
        color: 'blue',
        size: 'default',
        class: '',
        to: undefined,
    });
</script>

<template>
    <NuxtLink
        v-if="props.to"
        :to="props.to"
        :class="cn(buttonVariants({ intent, color, size }), props.class)">
        <slot />
    </NuxtLink>
    <Primitive
        v-else
        :as-child="props.asChild"
        :as="'button'"
        :to="props.to"
        :class="cn(buttonVariants({ intent, color, size }), props.class)">
        <slot />
    </Primitive>
</template>
