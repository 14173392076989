import { type VariantProps, cva } from 'class-variance-authority';

export { default as Button } from './Button.vue';

export const buttonVariants = cva(
    'flex w-fit items-center justify-center gap-3 text-balance rounded-full border-2 text-center font-bold leading-tight transition duration-300 hover:shadow-lg focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 active:shadow-none disabled:pointer-events-none disabled:border-neutral-200 disabled:bg-neutral-200 disabled:text-neutral-400',
    {
        variants: {
            intent: {
                primary: '',
                secondary: 'bg-transparent',
            },
            color: {
                blue: ' border-blue-400 active:border-blue-500 active:bg-blue-500',
                green: ' border-green-400 active:border-green-500 active:bg-green-500',
                red: ' border-red-400 active:border-red-500 active:bg-red-500',
                yellow: ' border-yellow-400 active:border-yellow-500 active:bg-yellow-500',
                white: ' border-shades-white active:border-neutral-100 active:bg-neutral-100',
            },

            size: {
                default: 'px-6 py-[10px]',
                // xs: 'h-7 rounded px-2',
                // sm: 'h-9 rounded-md px-3',
                // lg: 'h-11 rounded-md px-8',
                // icon: 'h-10 w-10',
            },
        },
        compoundVariants: [
            {
                intent: 'primary',
                color: 'blue',
                class: 'bg-blue-400 text-shades-white',
            },
            {
                intent: 'primary',
                color: 'green',
                class: 'bg-green-400 text-shades-white',
            },
            {
                intent: 'primary',
                color: 'red',
                class: 'bg-red-400 text-shades-white',
            },
            {
                intent: 'primary',
                color: 'yellow',
                class: 'bg-yellow-400 text-neutral-900',
            },
            {
                intent: 'primary',
                color: 'white',
                class: 'bg-shades-white text-blue-400',
            },
            {
                intent: 'secondary',
                color: 'blue',
                class: 'text-blue-400 hover:bg-blue-400 hover:text-shades-white',
            },
            {
                intent: 'secondary',
                color: 'green',
                class: 'text-green-400 hover:bg-green-400 hover:text-shades-white',
            },
            {
                intent: 'secondary',
                color: 'red',
                class: 'text-red-400 hover:bg-red-400 hover:text-shades-white',
            },
            {
                intent: 'secondary',
                color: 'white',
                class: 'text-shades-white hover:bg-shades-white hover:text-blue-400',
            },
        ],
        defaultVariants: {
            intent: 'primary',
            color: 'blue',
            size: 'default',
        },
    },
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;
