import { Country } from '~/types/enums/Country';

export function getCountryCodeFromUrl(slug: string | string[]): Country {
    // Check if slug is array
    if (!Array.isArray(slug)) {
        slug = slug.split('/');
    }

    const countryCode = slug.find((item) => {
        return Object.values(Country).includes(item as Country);
    });

    // If no country code is found, return none

    return countryCode ? (countryCode as Country) : Country.NONE;
}
