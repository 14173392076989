<template>
    <main>
        <section
            class="container mx-auto grid h-[90vh] place-content-center gap-8 px-5 py-8 text-center lg:px-8 lg:py-10">
            <div class="mx-auto">
                <img src="~/assets/images/ErrorIllustration.svg" alt="Error" />
            </div>
            <h1 v-if="errorText">
                {{
                    errorText.statusCode == 404 || errorText.statusCode == 500
                        ? $t(`error_page.${errorText.statusCode}`)
                        : $t('error_page.defaultError')
                }}
            </h1>
            <h2 v-if="errorText">{{ $t('error_page.error') }}: {{ errorText?.statusCode }}</h2>
            <p class="hidden">
                {{ errorText }}
            </p>
            <div class="mx-auto">
                <Button color="blue" intent="primary" @click="backToHomepage()">
                    {{ $t('error_page.button_back_to_start') }}
                </Button>
            </div>
        </section>
    </main>
</template>

<script setup lang="ts">
    import { Country } from '~/types/enums/Country';
    const { t } = useI18n();
    const { slug } = useRoute().params;
    const currentCountry = useCurrentCountry();

    // not possible to properly type error variable since third party libraries have major influence on type of error
    const nuxtError = useError();

    const errorText = nuxtError.value;

    /** Set current country if possible from different urls */
    if (slug) currentCountry.value = getCountryCodeFromUrl(slug);
    else if (errorText && errorText.url)
        currentCountry.value = getCountryCodeFromUrl(errorText.url);

    function backToHomepage() {
        if (errorText && errorText.statusCode == 404)
            clearError({
                redirect: currentCountry.value === Country.NONE ? '/' : `/${currentCountry.value}`,
            });
        else
            reloadNuxtApp({
                path: currentCountry.value === Country.NONE ? '/' : `/${currentCountry.value}`,
                persistState: false,
            });
    }

    useSeoMeta({
        title: () => `${t('error_page.error_title')}`,
        ogTitle: () => `${t('error_page.error_title')}`,
        description: () => `${t('error_page.error_title')}`,
        ogDescription: () => `${t('error_page.error_title')}`,
    });
</script>
