export default defineNuxtRouteMiddleware((to) => {
    if (!to.path.endsWith('/')) {
        return navigateTo(to.path + '/', {
            redirectCode: 301,
        });
    }

    if (to.path !== to.path.toLowerCase()) {
        return navigateTo(to.path.toLowerCase(), {
            redirectCode: 301,
        });
    }
});
