export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig();
    const { $posthog } = useNuxtApp();

    const posthog = $posthog();

    useHead({
        script: [
            {
                src: config.public.cookieFirstUrl,
                async: true,
            },
        ],
    });

    const cookieFirst = useCookieFirst();

    window.addEventListener('cf_init', () => {
        // @ts-ignore
        cookieFirst.value = window.CookieFirst;

        // Check if consent is already given (from former page visits)
        if (
            posthog &&
            posthog.config.disable_persistence &&
            cookieFirst.value.consent &&
            cookieFirst.value.consent.performance === true
        ) {
            posthog.set_config({ disable_persistence: false });
        }
    });

    window.addEventListener('cf_consent', (event: any) => {
        // When consent is changing, check if posthog is allowed
        if (posthog && event.detail.performance) {
            posthog.set_config({ disable_persistence: false });
        }
    });
});
