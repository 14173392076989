import * as Sentry from '@sentry/vue';
import posthog from 'posthog-js';

export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter();
    const config = useRuntimeConfig();

    // Cancel if no sentry dsn is set
    if (!config.public.sentryDsn) return;

    if (config.public.env === 'production') {
        Sentry.init({
            app: nuxtApp.vueApp,
            dsn: config.public.sentryDsn,
            environment: config.public.env,
            integrations: [
                Sentry.browserTracingIntegration({
                    router,
                    enableInp: true,
                }),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
                new posthog.SentryIntegration(posthog, 'instadrive', 1886744),
            ],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1, // Change in prod
            replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
            tracePropagationTargets: [
                'localhost:3000',
                'https://firmenwagen.insta-drive.com',
                'https://firmenwagen-preview.insta-drive.com',
                'https://preview.insta-drive.com',
                'https://insta-drive.com',
            ],
            beforeSend(event, hint) {
                // Check if it is an exception, and if so, log it.
                if (event.exception) {
                    // eslint-disable-next-line no-console
                    console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, {
                        event,
                        hint,
                    });
                }
                // Continue sending to Sentry
                return event;
            },
        });

        // Show Component level errors
        nuxtApp.vueApp.mixin(
            Sentry.createTracingMixins({
                trackComponents: true,
                timeout: 2000,
                hooks: ['activate', 'mount', 'update'],
            }),
        );
        Sentry.attachErrorHandler(nuxtApp.vueApp, {
            logErrors: false,
            attachProps: true,
            trackComponents: true,
            timeout: 2000,
            hooks: ['activate', 'mount', 'update'],
        });
    }

    nuxtApp.hook('vue:error', (error, instance, info) => {
        // handle error, e.g. report to a service
        Sentry.setContext('VueError', {
            error,
            instance,
            info,
        });
    });

    return {
        provide: {
            sentrySetContext: Sentry.setContext,
            sentrySetUser: Sentry.setUser,
            sentrySetTag: Sentry.setTag,
            sentryAddBreadcrumb: Sentry.addBreadcrumb,
            sentryCaptureException: Sentry.captureException,
        },
    };
});
